import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import { graphql } from "gatsby"
import CarouselContainer from "../components/common/carousel"
import HistoryArticle from "../components/history-article"
import ArticleScroller from "../components/common/article-scroller"
import styled from "styled-components"

export const query = graphql`
  {
    prismic {
      allHistorys {
        edges {
          node {
            images {
              image_information
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            articles {
              title
              text
            }
          }
        }
      }
    }
  }
`

const Divider = styled.div`
  width: 100vw;
  height: 10rem;
`
const Historia = ({ data }) => {
  const history = data.prismic.allHistorys.edges[0].node
  const [selectedArticle, setSelectedArticle] = useState(history.articles[0])
  const images = history.images.map(image => {
    return {
      information: image.image_information,
      fluid: image.imageSharp.childImageSharp.fluid,
    }
  })

  return (
    <Layout currentSiteUrl='/historia' hideFooter>
      <SEO title='Historia' />
      <CarouselContainer images={images} teasers={images} />
      <Divider />
      <ArticleScroller
        title='Historiska berättelser'
        articles={history.articles}
        handleClick={article => setSelectedArticle(article)}
        selectedArticle={selectedArticle}
      />

      <HistoryArticle article={selectedArticle} />
    </Layout>
  )
}
export default Historia
