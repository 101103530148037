import React from "react"
import styled from "styled-components"
import { DEFAULT_BLUE } from "../../../styles/common"
import moment from "moment"
import { RichText } from "prismic-reactjs"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  background-color: #eee;
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
`

const TeaserWrapper = styled.div`
`

const Title = styled.h2`
  color: ${DEFAULT_BLUE};
  text-align: center;
`

const Teaser = styled.div`
  width: 50rem;
  max-width: 85vw;
  padding: 2rem;
  margin: 0.5rem;
  color: ${DEFAULT_BLUE};
  border-radius: 0.5rem;
  box-shadow: 0px 0px 1px 1px ${DEFAULT_BLUE} inset;
  transition: all 0.5s ease-in-out;
  &:hover {
    background-color: ${DEFAULT_BLUE};
    color: lightgrey;
    cursor: pointer;
  }
  &.selected {
    background-color: ${DEFAULT_BLUE};
    color: lightgrey;
  }
`

const TeaserTitle = styled.h3`
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
`

const ArticleScroller = ({ title, articles, handleClick, selectedArticle }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <TeaserWrapper>
        {articles.map(n => (
          <Teaser
            onClick={() => handleClick(n)}
            className={selectedArticle === n ? "selected" : ""}
          >
            <TeaserTitle>{RichText.asText(n.title)}</TeaserTitle>
          </Teaser>
        ))}
      </TeaserWrapper>
    </Wrapper>
  )
}

export default ArticleScroller
