import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"

const ArticleWrapper = styled.div`
width: 100vw;
display: flex;
flex-direction: column;
align-items: center;
`

const Article = styled.div`
display: flex;
flex-direction: column;
width: 680px;
max-width: 100vw;
word-break: break-word;
& > p {
  font-size: 21px;
  letter-spacing: -0.003em;
  line-height: 32px;
  margin: 1rem;
  max-width: 100vw;
  overflow: hidden;
}
& > h1 {
  margin-top: 5rem;
}
align-items: center;
`

const HistoryArticle = ({article}) => {
  return (
    <ArticleWrapper>
        <Article>
          {RichText.render(article.title)}
          {RichText.render(article.text)}
        </Article>
    </ArticleWrapper>
  )
}

export default HistoryArticle